import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "d-inline"
  }, [_c(VBtn, {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialogOpen = true;
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-help-circle-outline")])], 1), _c('LBADialog', {
    attrs: {
      "max-width": 650,
      "persistent": true,
      "cancelButton": false
    },
    on: {
      "close": function ($event) {
        return _vm.cancel();
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Filtersuche mit Wildcards ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c(VTabs, {
          attrs: {
            "grow": ""
          },
          model: {
            value: _vm.dialogTab,
            callback: function ($$v) {
              _vm.dialogTab = $$v;
            },
            expression: "dialogTab"
          }
        }, [_c(VTab, {
          key: "generalTab"
        }, [_vm._v(" Allgemein ")]), _c(VTab, {
          key: "examplesTab"
        }, [_vm._v(" Beispiele ")])], 1), _c(VTabsItems, {
          staticClass: "pt-3",
          model: {
            value: _vm.dialogTab,
            callback: function ($$v) {
              _vm.dialogTab = $$v;
            },
            expression: "dialogTab"
          }
        }, [_c(VTabItem, {
          key: "generalTab"
        }, [_vm._v(" Filtersuchregeln: "), _c('ul', [_c('li', [_vm._v(" Bei der Filtersuche mit Wildcards werden Unterschiede in Groß- und Kleinschreibung zur Vereinfachung ignoriert. ")]), _c('li', [_vm._v("Alle Filter können beliebig kombiniert werden.")]), _c('li', [_vm._v(" Wenn ein Filter verwendet wird und kein Wildcard-Zeichen darin enthalten ist, so wird "), _c('b', [_vm._v("genau")]), _vm._v(" nach diesem Suchtext im entsprechenden Feld gesucht. \"Mustermann\" ergibt daher kein Ergebniss, das exakt übereinstimmende \"Mustermann Max\" hingegen schon. ")]), _c('li', [_vm._v(" Wenn Wildcard-Zeichen enthalten sind, dann wird unscharf gesucht. Der Suchtext ist in diesem Fall eine Schablone für die gewünschten Antworten. ")]), _c('li', [_vm._v(" Die folgenden Felder fungieren per Wildcard-Suche: Name, Straße, PLZ und Ort. ")])]), _c('br'), _vm._v(" Die Suche kann mit folgenden Wildcards zugeschnitten und erweitert werden: "), _c('ul', [_c('li', [_c('b', [_vm._v("*")]), _vm._v(" - ist ein Platzhalter für beliebig viele Zeichen")]), _c('li', [_c('b', [_vm._v("?")]), _vm._v(" - ist ein Platzhalter für genau ein Zeichen")])])]), _c(VTabItem, {
          key: "examplesTab"
        }, [_c('ul', [_c('li', [_c('b', [_vm._v("\"Mustermann Max\"")]), _vm._v(" in 'Name': liefert nur Adressen, deren Name exakt genau \"Mustermann Max\" ist, da keine Wildcard-Zeichen enthalten sind. ")]), _c('li', [_c('b', [_vm._v("\"Adler*\"")]), _vm._v(" in 'Name': alle Adressen die genau mit \"Adler\" beginnen, alle danach folgenden Zeichen sind egal. Es werden sowohl \"Adler Maria\" als auch \"Adlermayer Hermann\" gefunden. ")]), _c('li', [_c('b', [_vm._v("\"*Lukas\"")]), _vm._v(" in 'Name': alle Adressen, die genau mit \"Lukas\" enden. ")]), _c('li', [_c('b', [_vm._v("\"????straße*\"")]), _vm._v(" in 'Straße': alle Adressen, deren Straße mit beliebigen vier Zeichen beginnt, danach Straße enthält und beliebig endet. Zum Beispiel: Wolfstraße, Märzstraße usw. ")]), _c('li', [_c('b', [_vm._v("\"*straße\"")]), _vm._v(" in 'Straße' (man beachte das fehlende Sternchen am Ende): alle Adressen die mit \"straße\" (oder \"Straße\") enden, also bei denen keine Hausnummer hinterlegt ist. ")]), _c('li', [_c('b', [_vm._v("\"40??\"")]), _vm._v(" in 'PLZ': alle Adressen, deren Postleitzal mit 40 beginnt, also von 4000 bis inklusive 4099. ")])])])], 1)];
      },
      proxy: true
    }, {
      key: "success",
      fn: function () {
        return [_c('LBAButton', {
          attrs: {
            "buttonType": 'success'
          },
          on: {
            "click": function ($event) {
              return _vm.cancel();
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_vm._v("Verstanden")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.dialogOpen,
      callback: function ($$v) {
        _vm.dialogOpen = $$v;
      },
      expression: "dialogOpen"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
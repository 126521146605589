import axios from 'axios'

export default {
  //Gibt die Parameter für Filter mit und ladet dann die gefilterten Artikel

  filterArticle(
    //{ commit },

    akey,

    aartNr2,

    aarticleDescription,

    aarticleType,

    aarticleCategory,

    aarticleGroup
  ) {
    const promise = axios.post(`/v1/articles/search/filtered`, {
      key: akey,

      artNr2: aartNr2,

      articleDescription: aarticleDescription,

      articleType: aarticleType,

      articleCategory: aarticleCategory,

      articleGroup: aarticleGroup
    })

    return promise.then(response => response.data)
  }
}

import LBADialog from '@/components/ui/dialogs/LBADialog.vue';
export default {
  components: {
    LBADialog
  },
  data: () => ({
    dialogOpen: false,
    dialogTab: null
  }),
  methods: {
    cancel() {
      this.dialogOpen = false;
    }
  }
};
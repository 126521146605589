import ArticleSearch from '@/views/article/ArticleSearch.vue';
export default {
  components: {
    ArticleSearch
  },
  computed: {
    isIndexSite() {
      if (this.$route.name == 'Artikel') return true;
      return false;
    }
  }
};